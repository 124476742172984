














import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { priceFormat } from '~/helpers/utils';

type ProductThumbnail = {
  brand?: string;
  image: string;
  itemNo?: string;
  price?: number;
  title: string;
  url: string;
  description?: string;
};

export default defineComponent({
  components: {},
  props: {
    variant: {
      type: String as PropType<
        'product' | 'product-price' | 'inspiration' | 'article'
      >,
      default: 'product'
    },
    product: {
      type: Object as PropType<ProductThumbnail>,
      default: () => ({
        itemNo: '',
        title: '',
        price: 0,
        brand: '',
        image: '',
        url: '',
        description: ''
      })
    }
  },
  setup(props) {
    return {
      priceFormat,
      handleClick: () => {
        location.href = props.product.url;
      }
    };
  }
});
