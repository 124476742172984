






















import { defineComponent } from '@nuxtjs/composition-api';
import { useFooter } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'FooterSocialMedia',
  setup() {
    const { footerResult } = useFooter();
    const openUrl = (url: string) => {
      return window.open(url);
    };

    return {
      openUrl,
      socialMedias: footerResult.value.footer_social_media
    };
  }
});
