import { render, staticRenderFns } from "./SearchBar.vue?vue&type=template&id=1d54c6a0&scoped=true&"
import script from "./SearchBar.vue?vue&type=script&lang=ts&"
export * from "./SearchBar.vue?vue&type=script&lang=ts&"
import style0 from "./SearchBar.vue?vue&type=style&index=0&id=1d54c6a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d54c6a0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsBackButton: require('/var/www/packages/theme/components/atoms/BackButton.vue').default,IconsCross24px: require('/var/www/packages/theme/components/icons/cross/Cross24px.vue').default,IconsSearch24px: require('/var/www/packages/theme/components/icons/search/Search24px.vue').default,AtomsKESfInput: require('/var/www/packages/theme/components/atoms/KESfInput.vue').default})
