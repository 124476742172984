import { render, staticRenderFns } from "./Footnote.vue?vue&type=template&id=26619710&scoped=true&"
import script from "./Footnote.vue?vue&type=script&lang=ts&"
export * from "./Footnote.vue?vue&type=script&lang=ts&"
import style0 from "./Footnote.vue?vue&type=style&index=0&id=26619710&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26619710",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesFooterFootnoteLinks: require('/var/www/packages/theme/components/molecules/FooterFootnoteLinks.vue').default})
