import { useVSFContext } from '@vue-storefront/core';

const MARKETING_CONSENT_COOKIE = 'cookie_accepted';
const NOVULO_TRACKING_COOKIE = 'start_br';

export default function useMarketingTracking() {
  const { $cookies } = useVSFContext();

  const startTracking = () => {
    if (process.server) return;
    window.exponea?.start?.();
  };

  const getMarketingConsentCookie = () => {
    return $cookies.get(MARKETING_CONSENT_COOKIE);
  };

  const getNovuloTrackingCookie = () => {
    return $cookies.get(NOVULO_TRACKING_COOKIE);
  };

  const setMarketingConsentCookie = (value) => {
    $cookies.set(MARKETING_CONSENT_COOKIE, value, {
      path: '/',
      // 400 days
      maxAge: 60 * 60 * 24 * 400
    });
    if (value) {
      $cookies.set(NOVULO_TRACKING_COOKIE, value, {
        path: '/',
        // 400 days
        maxAge: 60 * 60 * 24 * 400
      });
    }
  };

  return {
    startTracking,
    getMarketingConsentCookie,
    getNovuloTrackingCookie,
    setMarketingConsentCookie
  };
}
