













































import {
  defineComponent,
  ref,
  PropType,
  computed,
  useContext
} from '@nuxtjs/composition-api';
import { SfInput, SfIcon, SfButton } from '@storefront-ui/vue';
import useWindowResize from '~/hooks/useResizeWindow';
import { useCurrentUser } from '@vue-storefront/novulo';

type SearhBarColorTypes = 'grey' | 'gray' | 'white';

export default defineComponent({
  name: 'SearchBar',
  components: {
    SfInput,
    SfIcon,
    SfButton
  },
  props: {
    handleInput: {
      type: Function,
      default: (__e = undefined) => {
        return __e;
      }
    },
    color: {
      type: String as PropType<SearhBarColorTypes>,
      default: 'white'
    },
    listenOnFocus: {
      type: Function,
      default: (__e = undefined) => {
        return __e;
      }
    },
    input: {
      type: String,
      default: ','
    }
  },
  setup(props, { emit }) {
    const { currentUser } = useCurrentUser();
    const classes = ref([]);
    const inputValue = ref('');
    const hasText = ref(inputValue.value !== '');
    const searchbar = ref(null);
    const showBackButton = ref(false);
    const { i18n } = useContext();

    const { isDesktop } = useWindowResize();

    const checkText = () => {
      hasText.value = inputValue.value !== '';
    };

    const handleEnterKey = ($event: KeyboardEvent) => {
      if (
        ($event.key === 'Enter' || $event.code === 'Enter') &&
        inputValue.value.length > 2
      ) {
        emit('search', inputValue.value);
      }
    };

    const placeholder = computed(() => {
      if (currentUser.value) {
        const userName =
          currentUser.value.contact.first_name ||
          currentUser.value.contact.company ||
          '';
        return (
          i18n.t('searchbar_hi_message') +
          userName +
          ', ' +
          i18n.t('searchbar_placeholder')
        );
      } else {
        return i18n.t('capital_searchbar_placeholder');
      }
    });

    return {
      inputValue,
      classes,
      handleChange: (e) => {
        props.handleInput(e);
        checkText();
      },
      handleHover: () => {
        classes.value.push('hover');
      },
      handleUnhover: () => {
        classes.value.splice(classes.value.indexOf('hover'), 1);
      },
      handleFocus: () => {
        props.listenOnFocus(true);
        classes.value.push('focus');
        if (!isDesktop.value) {
          showBackButton.value = true;
        }
        searchbar.value.$el.firstChild.firstChild.focus();
      },
      handleBlur: () => {
        props.listenOnFocus(false);
        classes.value.splice(classes.value.indexOf('focus'), 1);
        if (!isDesktop.value) {
          showBackButton.value = false;
        }
      },
      hasText,
      searchbar,
      handleClear: () => {
        inputValue.value = '';
        checkText();
        props.handleInput('');
        searchbar.value.$el.firstChild.firstChild.focus();
      },
      showBackButton,
      handleEnterKey,
      placeholder
    };
  }
});
