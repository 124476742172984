//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
import useUrlFormatter from '~/hooks/useUrlFormatter';

export default {
  name: 'MegaMenuLevelOne',
  props: {
    categoryVisible: {
      default: () => [],
      type: Array
    },
    categoryActive: {
      type: String,
      default: ''
    },
    activeTab: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const formatUrl = useUrlFormatter();

    const activeTabFriendlyName = computed(() => {
      return props.categoryVisible?.[0]?.parentCategory ?? '';
    });

    const hideLevelOne = () => {
      emit('onHideLevelOne');
    };

    const handleHover = (value) => {
      emit('onLevelOneChange', value);
    };

    const closeMenu = () => {
      emit('onClose');
    };

    return {
      formatUrl,
      activeTabFriendlyName,
      handleHover,
      hideLevelOne,
      closeMenu
    };
  }
};
