import { render, staticRenderFns } from "./Tab.vue?vue&type=template&id=e9ca0afc&scoped=true&"
import script from "./Tab.vue?vue&type=script&lang=ts&"
export * from "./Tab.vue?vue&type=script&lang=ts&"
import style0 from "./Tab.vue?vue&type=style&index=0&id=e9ca0afc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9ca0afc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsChevronDownIcon24px: require('/var/www/packages/theme/components/icons/chevron-down/ChevronDownIcon24px.vue').default,IconsChevronRight16px: require('/var/www/packages/theme/components/icons/chevron-right/ChevronRight16px.vue').default})
