


























































































































import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import { mediaUrlFallback } from '@/helpers/utils';
import useUrlFormatter from '~/hooks/useUrlFormatter';
import useMarketingTracking from '~/hooks/useMarketingTracking';
import useGoogleAnalytics, { eventBuilder } from '~/hooks/useGoogleAnalytics';

export default defineComponent({
  props: {
    logo: {
      type: String,
      default: ''
    }
  },
  setup() {
    const isVisibleModal = ref(true);
    const hasAcceptedMarketing = ref(false);
    const isSectionTwo = ref(false);
    const { i18n } = useContext();
    const formatUrl = useUrlFormatter();
    const { setMarketingConsentCookie, startTracking } = useMarketingTracking();
    const { registerEvent } = useGoogleAnalytics();
    const cookieStatementUrl = formatUrl(`/${i18n.t('cookie_statement_link')}`);

    const handleClose = () => {
      setMarketingConsentCookie(hasAcceptedMarketing.value);
      if (hasAcceptedMarketing.value) {
        registerEvent(eventBuilder('cookiesAccepted')());
        startTracking();
      }
    };

    const handleSaveButtonClick = () => {
      isVisibleModal.value = false;
    };

    const handleAcceptButtonClick = () => {
      hasAcceptedMarketing.value = true;
      isVisibleModal.value = false;
    };

    const onUpdate = () => {
      hasAcceptedMarketing.value = !hasAcceptedMarketing.value;
    };

    return {
      handleAcceptButtonClick,
      handleClose,
      handleSaveButtonClick,
      isVisibleModal,
      onUpdate,
      cookieStatementUrl,
      hasAcceptedMarketing,
      isSectionTwo,
      openSectionTwo() {
        isSectionTwo.value = true;
      }
    };
  },
  methods: {
    srcLogo() {
      return mediaUrlFallback(
        '/media/4e067df4-a171-4c3a-8cfb-63cb87a0cbbd/logo%20kamera%20express.svg'
      );
    }
  }
});
