//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    stroke: {
      type: String,
      default: '#646363'
    }
  }
};
