














import { defineComponent } from '@nuxtjs/composition-api';
import { useFooter } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'FooterFootnoteLinks',
  setup() {
    const { footerResult } = useFooter();
    return {
      links: footerResult.value.footer_footnote
    };
  }
});
