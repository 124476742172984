










import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CartIcon',
  props: {
    itemsCount: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const cartIcon = computed(() => {
      const itemsCount = props.itemsCount;
      if (itemsCount >= 8) return 'IconsTruckIcon24px';
      if (itemsCount >= 2) return 'IconsCartStack2Icon24px';
      if (itemsCount >= 1) return 'IconsCartStack1Icon24px';
      return 'IconsCartIcon24px';
    });

    return {
      cartIcon
    };
  }
});
