//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, computed, watch, reactive } from '@nuxtjs/composition-api';
export default {
  name: 'MegaMenu',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    tabActive: {
      type: String,
      default: ''
    },
    categories: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const TIME_TO_OPEN_MENU = 300;
    const TIME_TO_CHANGE_LEVEL1 = 300;
    const megaMenuOpen = ref(props.isOpen);
    const activeTab = ref(props.tabActive);
    const categoryVisible = ref([]);
    const merken = ref({});
    const producten = ref({});
    const services = ref({});
    const inspiratie = ref({});
    const levelOneShown = reactive({ merken, producten, services, inspiratie });
    let showMegaMenu;

    watch(
      () => props.isOpen,
      (newVal) => {
        if (newVal) {
          showMegaMenu = setTimeout(() => {
            megaMenuOpen.value = newVal;
          }, TIME_TO_OPEN_MENU);
        } else {
          clearTimeout(showMegaMenu);
          megaMenuOpen.value = newVal;
          emit('restartActiveTab');
        }
      }
    );
    watch(
      () => props.tabActive,
      (newVal) => {
        if (Object.keys(props.categories).length === 0) {
          emit('restartActiveTab');
          return;
        }
        if (!props.isOpen) return;
        activeTab.value = newVal;
        for (const category in props.categories) {
          if (!category.toLowerCase().indexOf(activeTab.value)) {
            categoryVisible.value = props.categories[category];
            if (!levelOneShown[activeTab.value]?.id) {
              levelOneShown[activeTab.value] = categoryVisible.value[0];
            }
          }
        }
      }
    );

    const activeTabType = computed(() => {
      const TAB_TYPES = {
        producten: 'default',
        merken: 'default',
        service: 'cards',
        inspiratie: 'default'
      };

      return TAB_TYPES[activeTab.value] || 'default';
    });

    return {
      activeTab,
      activeTabType,
      levelOneShown,
      megaMenuOpen,
      categoryVisible,
      TIME_TO_CHANGE_LEVEL1
    };
  },

  methods: {
    levelOneChange(val) {
      if (val && val.groups && val.groups.length > 0) {
        this.temporalHover = setTimeout(() => {
          this.levelOneShown[this.activeTab] = val;
        }, this.TIME_TO_CHANGE_LEVEL1);
      } else {
        clearTimeout(this.temporalHover);
      }
    }
  }
};
