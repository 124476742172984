





















import { defineComponent } from '@nuxtjs/composition-api';
import { mediaUrlFallback } from '@/helpers/utils';
import { useFooter } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'BusinessLogos',
  props: {
    grayscale: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { footerResult } = useFooter();
    const mediaUrl = (path: string): string => {
      return mediaUrlFallback(path);
    };

    return {
      mediaUrl,
      logos: footerResult.value?.footer_business_logos
    };
  }
});
