


















import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CloseButton',
  components: {
    SfButton
  },
  props: {
    disabled: { type: Boolean, default: false }
  }
});
