






















import {
  computed,
  defineComponent,
  ref,
  WritableComputedRef
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Checkbox',
  props: {
    noMargin: {
      type: Boolean,
      default: false
    },
    model: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    name: { type: String, default: '' },
    value: { type: String, default: '' }
  },

  setup(props, context) {
    const checkbox = ref(null);

    const selectedValue: WritableComputedRef<boolean> = computed({
      get(): boolean {
        return props.selected;
      },

      set(value: boolean): void {
        context.emit('onUpdate', { name, value });
      }
    });

    const toggleCheckbox = (e: MouseEvent): void => {
      checkbox.value.click();
    };

    return { selectedValue, checkbox, toggleCheckbox };
  }
});
