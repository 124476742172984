




























































































import {
  defineComponent,
  toRefs,
  PropType,
  watch,
  ref,
  computed
} from '@nuxtjs/composition-api';

type ItemAttributes = {
  name: string;
  values: Array<string>;
};
type resultProductType = {
  brand: 'string';
  image: 'string';
  itemno: 'string';
  price: number;
  title: 'string';
  description: 'string';
  url: 'string';
  attributes: Array<ItemAttributes>;
};

type suggestionType = {
  title: 'string';
};

export default defineComponent({
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    isResultEmpty: {
      type: Boolean,
      default: false
    },
    resultProducts: {
      type: Array as PropType<resultProductType[]>,
      default: () => []
    },
    resultSuggestions: {
      type: Array as PropType<suggestionType[]>,
      default: () => []
    },
    resultContent: {
      type: Array as PropType<resultProductType[]>,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const { resultProducts, resultContent } = toRefs(props);
    const MAXIMUM_PRODUCTS_VISIBLE = 3;
    const showSearch = ref(false);

    watch(
      () => ({ isFocused: props.isFocused, searchQuery: props.searchQuery }),
      ({ isFocused, searchQuery }) => {
        if (isFocused) showSearch.value = searchQuery.length >= 2;
      }
    );

    const handleSuggestionClick = (title) => {
      emit('click:suggestion', title);
    };

    const handleProductClick = (product) => {
      emit('click:product', product);
    };

    const handleContentClick = (content) => {
      emit('click:content', content);
    };

    const handleMoreProductsClick = () => {
      emit('click:moreProducts');
    };

    const handleMoreContentClick = () => {
      emit('click:moreContent');
    };

    return {
      showSearch,
      handleSuggestionClick,
      handleProductClick,
      handleContentClick,
      handleMoreProductsClick,
      handleMoreContentClick,
      listOfProductsToShow: computed(() => {
        const products = [];
        try {
          resultProducts.value.every((item) => {
            let description;
            if (products.length === MAXIMUM_PRODUCTS_VISIBLE) return false;
            const descriptionAttribute = item.attributes.find(
              (value) => value.name === 'description'
            );
            description = '';
            if ('values' in descriptionAttribute) {
              description = descriptionAttribute.values[0];
            }
            // should be fixed by BE
            if (item.brand) {
              products.push({
                brand: item.brand,
                image: item.image,
                itemsNo: item.itemno,
                price: item.price,
                title: item.title,
                description,
                url: item.url,
                attributes: item.attributes
              });
            }
            return true;
          });
        } catch (e) {
          console.log(e);
        }

        return products.map((product) => {
          const promotionPrice =
            product.attributes?.find(
              (attribute) => attribute.name === 'actieprijs'
            )?.values?.[0] ?? '';

          let currentPrice = product.price;
          if (promotionPrice) {
            const promotionPriceNumber = Number(
              promotionPrice.replace(',', '.')
            );
            if (promotionPriceNumber < currentPrice) {
              currentPrice = promotionPriceNumber;
            }
          }

          return {
            ...product,
            price: currentPrice
          };
        });
      }),
      listOfContentToShow: computed(() => {
        const content = [];
        try {
          resultContent.value.every((item, index) => {
            let description;
            if (index === MAXIMUM_PRODUCTS_VISIBLE) return false;
            const descriptionAttribute = item.attributes.find(
              (value) => value.name === 'description'
            );
            description = '';
            if ('values' in descriptionAttribute) {
              description = descriptionAttribute.values[0];
            }
            content.push({
              brand: item.brand,
              image: item.image,
              itemsNo: item.itemno,
              price: item.price,
              title: item.title,
              description,
              url: item.url
            });
            return true;
          });
        } catch (e) {
          console.log(e);
        }

        return content;
      })
    };
  }
});
