//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfIcon, SfButton } from '@storefront-ui/vue';
import { focus } from '@storefront-ui/vue/src/utilities/directives';
export default {
  name: 'KESfInput',
  directives: {
    focus
  },
  components: { SfIcon, SfButton },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    valid: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
      description: 'Native input required attribute'
    },
    disabled: {
      type: Boolean,
      default: false,
      description: 'Native input disabled attribute'
    },
    hasShowPassword: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPasswordVisible: false,
      inputType: '',
      isNumberTypeSafari: false
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit('input', event.target.value)
      };
    },
    isPassword() {
      return this.type === 'password' && this.hasShowPassword;
    },
    idWithoutWhitespace() {
      return this.name.replace(/\s/g, '');
    }
  },
  watch: {
    type: {
      immediate: true,
      handler(type) {
        let inputType = type;
        // Safari has bug for number input
        if (typeof window !== 'undefined' || typeof document !== 'undefined') {
          const ua = navigator.userAgent.toLocaleLowerCase();
          if (
            ua.indexOf('safari') !== -1 &&
            ua.indexOf('chrome') === -1 &&
            type === 'number'
          ) {
            this.isNumberTypeSafari = true;
            inputType = 'text';
          }
        }
        this.inputType = inputType;
      }
    },
    value: {
      immediate: true,
      handler(value) {
        if (!this.isNumberTypeSafari) return;
        if (isNaN(value)) {
          this.$emit('input');
        }
      }
    }
  },
  methods: {
    switchVisibilityPassword() {
      this.isPasswordVisible = !this.isPasswordVisible;
      this.inputType = this.isPasswordVisible ? 'text' : 'password';
    }
  }
};
