import { render, staticRenderFns } from "./Checkbox.vue?vue&type=template&id=2b37119a&scoped=true&"
import script from "./Checkbox.vue?vue&type=script&lang=ts&"
export * from "./Checkbox.vue?vue&type=script&lang=ts&"
import style0 from "./Checkbox.vue?vue&type=style&index=0&id=2b37119a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b37119a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsCheckboxOn20px: require('/var/www/packages/theme/components/icons/checkbox-on/CheckboxOn20px.vue').default,IconsCheckboxOff20px: require('/var/www/packages/theme/components/icons/checkbox-off/CheckboxOff20px.vue').default})
