
















import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

type TextLinkColor = 'default' | 'orange';
type TextLinkTypes = 'primary' | 'secondary' | 'small';

export default defineComponent({
  name: 'TextLink',
  components: {
    SfLink
  },
  props: {
    link: {
      type: String,
      default: '/'
    },
    color: {
      type: String as PropType<TextLinkColor>,
      default: ''
    },
    variant: {
      type: String as PropType<TextLinkTypes>,
      default: 'primary'
    },
    target: {
      default: '_self'
    }
  },
  setup(props) {
    const hasNoAction = computed(() => props.link === '#');

    const targetLink = props.target;
    return {
      hasNoAction,
      targetLink
    };
  }
});
