











import { defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'Promo',
  components: {
    SfLink
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    description: {
      type: String,
      required: true,
      default: ''
    },
    image: {
      type: String,
      required: true,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_self'
    }
  }
});
