


















































































import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';
import { SfDropdown } from '@storefront-ui/vue';
import { useLanguageSelection } from '@vue-storefront/novulo';
import { extractPathFromUrl } from '~/helpers/utils';

export default defineComponent({
  name: 'LanguageSelector',
  components: {
    SfDropdown
  },
  props: {
    isSmall: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const isOpen = ref(false);
    const showLanguages = ref(false);
    const { i18n, $config, route } = useContext();
    const languages = Object.keys($config.domainConfig.lang);
    const selectedLanguageValue = i18n.locale.toUpperCase();

    const { getSlugRedirects } = useLanguageSelection();

    const handleToggle = () => {
      isOpen.value = !isOpen.value;
    };

    const handleClose = () => {
      isOpen.value = false;
    };

    const mappedListOfLanguages = () => {
      return Object.values(i18n.locales).filter((lang) =>
        languages.includes(lang.iso)
      );
    };

    const handleLanguageChange = async (languageValue: string) => {
      showLanguages.value = false;
      isOpen.value = false;
      const pathName = extractPathFromUrl(route.value.path);
      const currentHost = window.location.host;
      const slugRedirects = await getSlugRedirects(pathName, currentHost);
      const redirectionUrl = slugRedirects?.[languageValue.toLowerCase()];
      if (!redirectionUrl) return;
      const queryParams = window.location.search;
      window.location.href = redirectionUrl + queryParams;
    };

    return {
      isOpen,
      handleToggle,
      handleClose,
      showLanguages,
      mappedListOfLanguages,
      languages,
      selectedLanguageValue,
      handleLanguageChange
    };
  }
});
