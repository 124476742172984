import { render, staticRenderFns } from "./LinksMegaMenu.vue?vue&type=template&id=dcdbc78e&"
import script from "./LinksMegaMenu.vue?vue&type=script&lang=js&"
export * from "./LinksMegaMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsMegaMenuLevelOne: require('/var/www/packages/theme/components/atoms/MegaMenuLevelOne.vue').default,AtomsMegaMenuLevelTwo: require('/var/www/packages/theme/components/atoms/MegaMenuLevelTwo.vue').default})
