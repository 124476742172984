import { render, staticRenderFns } from "./LanguageSelector.vue?vue&type=template&id=aaa5ce72&scoped=true&"
import script from "./LanguageSelector.vue?vue&type=script&lang=ts&"
export * from "./LanguageSelector.vue?vue&type=script&lang=ts&"
import style0 from "./LanguageSelector.vue?vue&type=style&index=0&id=aaa5ce72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaa5ce72",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsGlobeIcon16px: require('/var/www/packages/theme/components/icons/globe/GlobeIcon16px.vue').default,IconsGlobeIcon24px: require('/var/www/packages/theme/components/icons/globe/GlobeIcon24px.vue').default,IconsChevronUpIcon16px: require('/var/www/packages/theme/components/icons/chevron-up/ChevronUpIcon16px.vue').default,IconsChevronDownIcon16px: require('/var/www/packages/theme/components/icons/chevron-down/ChevronDownIcon16px.vue').default,IconsCheckmark24px: require('/var/www/packages/theme/components/icons/checkmark/Checkmark24px.vue').default,IconsChevronRight16px: require('/var/www/packages/theme/components/icons/chevron-right/ChevronRight16px.vue').default,IconsChevronLeft16px: require('/var/www/packages/theme/components/icons/chevron-left/ChevronLeft16px.vue').default})
