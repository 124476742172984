import { render, staticRenderFns } from "./FooterSocialMedia.vue?vue&type=template&id=c789974e&scoped=true&"
import script from "./FooterSocialMedia.vue?vue&type=script&lang=ts&"
export * from "./FooterSocialMedia.vue?vue&type=script&lang=ts&"
import style0 from "./FooterSocialMedia.vue?vue&type=style&index=0&id=c789974e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c789974e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsSocialFacebook: require('/var/www/packages/theme/components/icons/social/Facebook.vue').default,IconsSocialInstagram: require('/var/www/packages/theme/components/icons/social/Instagram.vue').default,IconsSocialYoutube: require('/var/www/packages/theme/components/icons/social/Youtube.vue').default,IconsSocialLinkedin: require('/var/www/packages/theme/components/icons/social/Linkedin.vue').default,IconsSocialMail: require('/var/www/packages/theme/components/icons/social/Mail.vue').default,IconsSocialTwitter: require('/var/www/packages/theme/components/icons/social/Twitter.vue').default,AtomsSocialContactButton: require('/var/www/packages/theme/components/atoms/SocialContactButton.vue').default})
