//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
import { useTranslations } from '@vue-storefront/novulo';
import useUrlFormatter from '~/hooks/useUrlFormatter';

export default {
  name: 'MegaMenuLevelOne',
  props: {
    categoryVisible: {
      default: () => [],
      type: Array
    },
    categoryActive: {
      type: String,
      default: ''
    },
    activeTab: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { translate } = useTranslations();
    const formatUrl = useUrlFormatter();

    const activeTabFriendlyName = computed(() => {
      return props.categoryVisible?.[0]?.parentCategory ?? '';
    });

    const cards = computed(() => props.categoryVisible?.[0]?.cards || []);

    const allServicesUrl = computed(() =>
      formatUrl(props.categoryVisible?.[0]?.mainRef)
    );

    const hideLevelOne = () => {
      emit('onHideLevelOne');
    };

    const handleHover = (value) => {
      emit('onLevelOneChange', value);
    };

    const closeMenu = () => {
      emit('onClose');
    };

    return {
      cards,
      allServicesUrl,
      formatUrl,
      activeTabFriendlyName,
      handleHover,
      hideLevelOne,
      closeMenu,
      translate
    };
  }
};
