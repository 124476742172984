//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  defineComponent,
  ref,
  onMounted,
  useRoute
} from '@nuxtjs/composition-api';
import useMarketingTracking from '~/hooks/useMarketingTracking';
import LazyHydrate from 'vue-lazy-hydration';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    LazyHydrate
  },
  setup() {
    const route = useRoute();
    const isAccepted = ref(false);
    const {
      getMarketingConsentCookie,
      getNovuloTrackingCookie,
      startTracking
    } = useMarketingTracking();

    onMounted(() => {
      isAccepted.value = getMarketingConsentCookie();
      if (getNovuloTrackingCookie()) {
        startTracking();
      }
    });

    return { route, isAccepted };
  }
});
