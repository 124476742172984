







































































import { defineComponent, ref } from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';

import { useFooter } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'FooterQuickLinks',
  setup() {
    const { isDesktop } = useWindowResize();
    const { footerResult } = useFooter();

    const openAccordionId = ref<number | null>(null);

    const toggleAccordion = (id: number) => {
      if (openAccordionId.value === id) {
        openAccordionId.value = null;
      } else {
        openAccordionId.value = id;
      }
    };

    return {
      isDesktop,
      quickLinks: footerResult.value.footer_quicklinks,
      openAccordionId,
      toggleAccordion
    };
  }
});
