//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfBar, SfOverlay, SfIcon, SfButton } from '@storefront-ui/vue';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { focusTrap } from '@storefront-ui/vue/src/utilities/directives';
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives';
import { isClient } from '@storefront-ui/vue/src/utilities/helpers';
export default {
  name: 'KESfModal',
  directives: {
    focusTrap,
    clickOutside
  },
  components: {
    SfBar,
    SfOverlay,
    SfIcon,
    SfButton
  },
  model: {
    prop: 'visible',
    event: 'close'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    disableBody: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    cross: {
      type: Boolean,
      default: true
    },
    overlay: {
      type: Boolean,
      default: true
    },
    persistent: {
      type: Boolean,
      default: false
    },
    transitionOverlay: {
      type: String,
      default: 'sf-fade'
    },
    transitionModal: {
      type: String,
      default: 'sf-fade'
    }
  },
  data() {
    return {
      staticClass: null,
      className: null
    };
  },
  watch: {
    visible: {
      handler: function (value) {
        if (!isClient) return;
        if (value) {
          this.$nextTick(() => {
            if (this.disableBody) disableBodyScroll(this.$refs.content);
          });
          document.addEventListener('keydown', this.keydownHandler);
        } else {
          clearAllBodyScrollLocks();
          document.removeEventListener('keydown', this.keydownHandler);
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    checkPersistence() {
      if (!this.persistent) {
        this.close();
      }
    },
    keydownHandler(e) {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        this.close();
      }
    },
    classHandler() {
      if (this.staticClass !== this.$vnode.data.staticClass) {
        this.staticClass = this.$vnode.data.staticClass;
      }
      if (this.className !== this.$vnode.data.class) {
        this.className = this.$vnode.data.class;
      }
    }
  }
};
