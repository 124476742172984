//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, useContext } from '@nuxtjs/composition-api';

import useUrlFormatter from '~/hooks/useUrlFormatter';

export default {
  name: 'MegaMenuLevelTwo',
  props: {
    levelOne: {
      type: Object,
      default: () => ({})
    },
    activeTab: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { $config } = useContext();

    const isLevelThreeVisible = ref(false);
    const levelThree = ref([]);
    const formatUrl = useUrlFormatter();

    const mediaUrl = (path) => {
      return `${$config.mediaBaseURL}${path}`;
    };

    return {
      mediaUrl,
      formatUrl,
      levelThree,
      isLevelThreeVisible
    };
  },

  methods: {
    showLevelThree(index) {
      if (index === false) {
        this.levelThree = [];
        this.isLevelThreeVisible = false;
        return;
      }
      this.levelThree = this.levelOne.groups[index];
      this.isLevelThreeVisible = true;
    },
    hideLevelOne() {
      this.isLevelThreeVisible = false;
      this.$emit('onHideLevelOne');
    },
    hideLevelTwo() {
      this.isLevelThreeVisible = false;
      this.$emit('onHideLevelTwo');
    },
    closeMenu() {
      this.isLevelThreeVisible = false;
      this.$emit('onClose');
    }
  }
};
