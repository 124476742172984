






































import {
  defineComponent,
  ref,
  computed,
  PropType
} from '@nuxtjs/composition-api';

type AccordionVariant = 'primary' | 'secondary' | 'no-border';

export default defineComponent({
  name: 'Accordion',
  props: {
    variant: {
      type: String as PropType<AccordionVariant>,
      default: 'primary'
    },
    startOpen: {
      type: Boolean,
      default: false
    },
    verticalContent: {
      type: Boolean,
      default: false
    },
    isOutsideControllable: {
      type: Boolean,
      default: false
    },
    controllableIsOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const innerControlIsOpen = ref(props.startOpen);

    const toggleAccordion = () => {
      if (props.isOutsideControllable) {
        emit('toggle');
      } else {
        innerControlIsOpen.value = !innerControlIsOpen.value;
      }
    };

    const isOpen = computed(() => {
      if (props.isOutsideControllable) {
        return props.controllableIsOpen;
      } else {
        return innerControlIsOpen.value;
      }
    });

    return {
      toggleAccordion,
      isOpen
    };
  }
});
