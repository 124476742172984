export default function useDebouncer() {
  let debouncer = null;
  const debounce = (callback, time = 300) => {
    clearTimeout(debouncer);
    debouncer = setTimeout(callback, time);
  };

  return {
    debounce
  };
}
