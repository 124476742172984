


















import { defineComponent, onServerPrefetch } from '@nuxtjs/composition-api';
import { useFooter } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'KEFooter',
  setup() {
    const { getFooter } = useFooter();

    onServerPrefetch(async () => {
      await getFooter();
    });
  }
});
