















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    cardData: {
      type: Object,
      required: true,
      default: {}
    }
  },
  setup() {}
});
