var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sf-input",class:{
    'has-text': !!_vm.value,
    invalid: !_vm.valid
  },attrs:{"data-testid":_vm.name}},[_c('div',{staticClass:"sf-input__wrapper"},[_c('input',_vm._g(_vm._b({directives:[{name:"focus",rawName:"v-focus"}],class:{ 'sf-input--is-password': _vm.isPassword },attrs:{"id":_vm.idWithoutWhitespace,"required":_vm.required,"disabled":_vm.disabled,"name":_vm.name,"type":_vm.inputType},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners)),_vm._v(" "),_c('label',{staticClass:"sf-input__label",class:{ 'display-none': !_vm.label },attrs:{"for":_vm.name}},[_vm._t("label",function(){return [_vm._v("\n        "+_vm._s(_vm.label)+"\n      ")]},null,{ label: _vm.label })],2),_vm._v(" "),_vm._t("show-password",function(){return [_c('SfButton',{staticClass:"sf-input__password-button",class:{ 'display-none': !_vm.isPassword },attrs:{"type":"button","aria-label":"switch-visibility-password","aria-pressed":_vm.isPasswordVisible.toString()},on:{"click":_vm.switchVisibilityPassword}},[_c('SfIcon',{staticClass:"sf-input__password-icon",class:{
            hidden: !_vm.isPasswordVisible
          },attrs:{"icon":"show_password","size":"1.5rem"}})],1)]},null,{
        isPasswordVisible: _vm.isPasswordVisible,
        switchVisibilityPassword: _vm.switchVisibilityPassword
      }),_vm._v(" "),_vm._t("icon")],2),_vm._v(" "),_c('div',{staticClass:"sf-input__error-message"},[_c('transition',{attrs:{"name":"sf-fade"}},[_vm._t("error-message",function(){return [_c('div',{class:{ 'display-none': _vm.valid }},[_vm._v("\n          "+_vm._s(_vm.errorMessage)+"\n        ")])]},null,{ errorMessage: _vm.errorMessage })],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }