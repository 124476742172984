import { onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';

export default function useWindowScroll(threshold) {
  const passedThreshold = ref(false);
  const scrollY = ref(0);

  const handleScroll = () => {
    passedThreshold.value = window.scrollY > threshold;
    scrollY.value = window.scrollY;
  };

  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });

  return { passedThreshold, scrollY };
}
