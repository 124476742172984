//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LinksMegaMenu',
  props: {
    categories: {
      type: Object,
      default: () => ({})
    }
  }
};
