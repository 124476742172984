
























































































import {
  defineComponent,
  ref,
  computed,
  watch,
  useContext
} from '@nuxtjs/composition-api';
import { useCurrentUser, useHeader } from '@vue-storefront/novulo';
import useUrlFormatter from '~/hooks/useUrlFormatter';

export default defineComponent({
  props: {
    isDropdownOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const context = useContext();

    const { currentUser } = useCurrentUser();
    const { headerResult } = useHeader();

    const formatUrl = useUrlFormatter();

    const username = computed(() => {
      if (currentUser.value) {
        return (
          currentUser.value.contact.first_name ||
          currentUser.value.contact.company ||
          ''
        );
      } else {
        return null;
      }
    });

    const iconButtons = (code) => {
      if (!headerResult.value) return [];
      return (
        headerResult.value
          .find((d) => d.code === 'header_icon_buttons')
          ?.elements?.[0]?.urls.find((e) => e.code_english === code) || []
      );
    };

    const handleRedirectToAccount = () => {
      const buttonData = iconButtons('account');
      if (!buttonData?.url) return;
      window.location.href = formatUrl(buttonData.url);
    };

    const loginUrl = formatUrl(context.i18n.t('login_url') as string);
    const registerUrl = formatUrl(context.i18n.t('register_url') as string);

    // DROPDOWN
    const HOVER_OPEN_DELAY_MS = 200;
    const OPEN_TO_CLOSE_DEBOUNCE_MS = 200;

    // add debounce from open to close
    const canCloseDropdown = ref(true);
    watch(
      () => props.isDropdownOpen,
      (isOpen) => {
        if (isOpen) {
          canCloseDropdown.value = false;
          setTimeout(() => {
            canCloseDropdown.value = true;
          }, OPEN_TO_CLOSE_DEBOUNCE_MS);
        }
      }
    );
    const handleCloseDropdown = () => {
      if (!canCloseDropdown.value) return;
      emit('toggleDropdown', false);
    };

    const handleToggleLoginDropdown = () => {
      if (!canCloseDropdown.value) return;
      emit('toggleDropdown');
    };

    let hoverTimeout;
    const handleMouseOverLoginDropdown = () => {
      if (hoverTimeout || props.isDropdownOpen) return;
      hoverTimeout = setTimeout(() => {
        emit('toggleDropdown', true);
      }, HOVER_OPEN_DELAY_MS);
    };

    const handleMouseLeaveLoginDropdown = () => {
      if (!hoverTimeout) return;
      clearTimeout(hoverTimeout);
      hoverTimeout = null;
    };

    const clickOutsideConfig = {
      handler: handleCloseDropdown,
      middleware: (event) => {
        // check if click is inside the dropdown (there are two dropdowns, one for desktop and one for mobile)
        const loginDropdowns = Array.from(
          document.querySelectorAll('.login-dropdown')
        );
        return !loginDropdowns.some((dropdown) =>
          dropdown.contains(event.target)
        );
      },
      events: ['click']
    };

    const registerListItems = (
      context.i18n.t('login_dropdown_register_list') as string
    ).split(' * ');

    return {
      currentUser,
      username,
      iconButtons,
      handleRedirectToAccount,
      handleToggleLoginDropdown,
      handleMouseOverLoginDropdown,
      handleMouseLeaveLoginDropdown,
      clickOutsideConfig,
      registerListItems,
      loginUrl,
      registerUrl
    };
  }
});
