




































import { defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api';
import { SfBar } from '@storefront-ui/vue';
import useWindowResize from '@/hooks/useResizeWindow';

type ModalVariant = 'center' | 'bottom' | 'full';

export default defineComponent({
  components: { SfBar },
  props: {
    persistent: { type: Boolean, default: false },
    visible: { type: Boolean, default: false },
    disableBody: { type: Boolean, default: true },
    fit: { type: Boolean, default: false },
    variant: { type: String as PropType<ModalVariant>, default: 'bottom' },
    title: { type: String, default: '' }
  },
  setup(props, { emit }) {
    const { isDesktop } = useWindowResize();
    const isVisible = ref(props.visible);

    const closeModal = (isOpen) => {
      isVisible.value = isOpen;
      if (!isOpen) emit('onClose');
    };

    watch(
      () => props.visible,
      (newVal) => {
        closeModal(newVal);
      }
    );

    return {
      isDesktop,
      isVisible,
      closeModal
    };
  }
});
