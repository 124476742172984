
import { ref, onErrorCaptured } from '@nuxtjs/composition-api';
import { Logger } from '@vue-storefront/core';

export default {
  name: 'ErrorBoundary',
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const hasError = ref(false);

    onErrorCaptured((err) => {
      hasError.value = true;
      Logger.error(
        `Error captured in ErrorBoundary ${props.name}, will not render children: ${err}`
      );
      return false;
    });

    return {
      hasError
    };
  },
  render() {
    if (this.hasError || !this.$slots.default?.length) return null;
    return this.$slots.default[0];
  }
};
