












import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

type SkeletonNavigationButtonVariant =
  | 'basic'
  | 'highlight'
  | 'sub-navigation'
  | 'sub-return';

export default defineComponent({
  name: 'SkeletonNavigationButton',
  components: {
    SfButton
  },
  props: {
    variant: {
      type: String as PropType<SkeletonNavigationButtonVariant>,
      default: 'basic'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
});
