import { render, staticRenderFns } from "./SkeletonNavigationButton.vue?vue&type=template&id=4c3d9461&scoped=true&"
import script from "./SkeletonNavigationButton.vue?vue&type=script&lang=ts&"
export * from "./SkeletonNavigationButton.vue?vue&type=script&lang=ts&"
import style0 from "./SkeletonNavigationButton.vue?vue&type=style&index=0&id=4c3d9461&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c3d9461",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsChevronLeft16px: require('/var/www/packages/theme/components/icons/chevron-left/ChevronLeft16px.vue').default,IconsChevronRight16px: require('/var/www/packages/theme/components/icons/chevron-right/ChevronRight16px.vue').default})
