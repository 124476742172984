import { render, staticRenderFns } from "./MegaMenu.vue?vue&type=template&id=5530060f&scoped=true&"
import script from "./MegaMenu.vue?vue&type=script&lang=js&"
export * from "./MegaMenu.vue?vue&type=script&lang=js&"
import style0 from "./MegaMenu.vue?vue&type=style&index=0&id=5530060f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5530060f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCardMegaMenuLevelOne: require('/var/www/packages/theme/components/atoms/CardMegaMenuLevelOne.vue').default,AtomsMegaMenuLevelOne: require('/var/www/packages/theme/components/atoms/MegaMenuLevelOne.vue').default,AtomsMegaMenuLevelTwo: require('/var/www/packages/theme/components/atoms/MegaMenuLevelTwo.vue').default})
