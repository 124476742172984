var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mini-cart-container"},[_c('div',{staticClass:"mini-cart-button-container",class:{ 'is-open': _vm.isDropdownOpen }},[_c('AtomsKEButton',{staticClass:"mini-cart-button",attrs:{"icon-only":!_vm.isDesktop,"variant":"tertiary-no-underline"},on:{"click":_vm.handleToggle,"mouseleave":_vm.handleMouseLeave,"mouseover":_vm.handleMouseOver},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('AtomsCartIcon',{attrs:{"items-count":_vm.cartItemsCount}}),_vm._v(" "),_c('div',{staticClass:"shopping-cart-button"},[_vm._v("\n          "+_vm._s(_vm.$t('shopping_cart'))+"\n          "),_c('IconsChevronDownIcon16px')],1)]},proxy:true}])})],1),_vm._v(" "),(_vm.isDropdownOpen)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutsideConfig),expression:"clickOutsideConfig"}],staticClass:"mini-cart-dropdown"},[_c('div',{staticClass:"items-container",class:{
        'has-items': _vm.cartItems && _vm.cartItems.length > 0,
        'no-inspiration': _vm.cartLinks.inspirationPage === '#'
      }},[(!_vm.cartItems || _vm.cartItems.length === 0)?_c('div',{staticClass:"empty-cart-container"},[_c('div',{staticClass:"empty-cart-text-container"},[_c('h5',[_vm._v(_vm._s(_vm.$t('cart_empty')))]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('discover_offers'))+"\n          ")])]),_vm._v(" "),_c('AtomsKEButton',{on:{"click":function($event){return _vm.openLink(_vm.homeLink)}}},[_vm._v("\n          "+_vm._s(_vm.$t('continue_shopping'))+"\n        ")])],1):_c('div',{staticClass:"items-inner-container"},[_c('h5',[_vm._v(_vm._s(_vm.$t('shopping_cart')))]),_vm._v(" "),_c('div',{staticClass:"cart-products-container"},[_vm._l((_vm.cartItems),function(item){return _c('MoleculesMiniCartItem',{key:item.id,attrs:{"item":item},on:{"remove":function($event){return _vm.handleRemoveItem(item)}}})}),_vm._v(" "),(_vm.discountTotal > 0)?_c('div',{staticClass:"cart-discount-container"},[_c('span',{staticClass:"discount-title"},[_vm._v(_vm._s(_vm.translate('Korting')))]),_vm._v(" "),_c('span',{staticClass:"discount-total"},[_vm._v(_vm._s(_vm.addCurrency(_vm.priceFormat(_vm.discountTotal))))])]):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"cart-price-box"},[_c('div',{staticClass:"cart-price-box-row"},[_c('p',{staticClass:"price-description"},[_vm._v(_vm._s(_vm.$t('total_amount')))]),_vm._v(" "),_c('p',{staticClass:"price-text"},[_vm._v("\n              "+_vm._s(_vm.addCurrency(_vm.priceFormat(_vm.totalPrice)))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"cart-price-box-row buttons"},[_c('AtomsKEButton',{attrs:{"variant":"tertiary","data-dd-action-name":"view-cart"},on:{"click":function($event){return _vm.openLink(_vm.cartLinks.cartPage, {
                  action: 'view-cart'
                })}}},[_vm._v("\n              "+_vm._s(_vm.$t('edit_cart'))+"\n            ")]),_vm._v(" "),_c('AtomsKEButton',{attrs:{"full-width":!_vm.isDesktop,"data-dd-action-name":"view-cart"},on:{"click":function($event){return _vm.openLink(_vm.cartLinks.cartPage, {
                  action: 'view-cart'
                })}}},[_vm._v("\n              "+_vm._s(_vm.$t('mini_cart_order_button'))+"\n            ")])],1)])])]),_vm._v(" "),(
        (!_vm.cartItems || _vm.cartItems.length === 0) &&
        _vm.cartLinks.inspirationPage !== '#'
      )?_c('div',{staticClass:"inspiration-container"},[_c('div',{staticClass:"inspiration-text-container"},[_c('h5',[_vm._v(_vm._s(_vm.$t('cart_inspiration_title')))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('cart_inspiration_text')))]),_vm._v(" "),_c('AtomsKEButton',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.openLink(_vm.cartLinks.inspirationPage)}}},[_vm._v("\n          "+_vm._s(_vm.$t('view_all_inspiration'))+"\n        ")])],1)]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }