//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    stroke: { type: String, default: 'currentColor' }
  }
});
