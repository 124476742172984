























export default {
  props: {
    color: {
      type: String,
      default: '#646363'
    }
  }
};
