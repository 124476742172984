import { onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';

export const DESKTOP_BREAKPOINT = 768;

export default function useWindowResize() {
  const height = ref(null);
  const width = ref(null);
  const isDesktop = ref(false);

  function resize() {
    height.value = window.innerHeight;
    width.value = window.innerWidth;
    isDesktop.value = window.innerWidth >= DESKTOP_BREAKPOINT;
  }

  onMounted(() => {
    resize();
    window.addEventListener('resize', resize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', resize);
  });

  return { height, width, isDesktop };
}
