








import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton, SfIcon } from '@storefront-ui/vue';

export default defineComponent({
  components: { SfButton, SfIcon },
  props: {
    handleClick: {
      type: Function,
      default: (__e = undefined) => {
        return __e;
      }
    }
  }
});
