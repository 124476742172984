





















import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useTranslations } from '@vue-storefront/novulo';
import useUrlFormatter from '~/hooks/useUrlFormatter';

export default defineComponent({
  props: {
    categoryVisible: {
      default: () => []
    }
  },
  setup(props) {
    const { translate } = useTranslations();
    const formatUrl = useUrlFormatter();

    const cards = computed(() => props.categoryVisible?.[0]?.cards || []);

    const allServicesUrl = computed(() =>
      formatUrl(props.categoryVisible?.[0]?.mainRef)
    );

    return {
      cards,
      allServicesUrl,
      formatUrl,
      translate
    };
  }
});
