











import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Footnote',
  setup() {
    return {
      currentYear: new Date().getFullYear().toString()
    };
  }
});
