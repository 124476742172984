import { render, staticRenderFns } from "./KEFooter.vue?vue&type=template&id=f609f59a&scoped=true&"
import script from "./KEFooter.vue?vue&type=script&lang=ts&"
export * from "./KEFooter.vue?vue&type=script&lang=ts&"
import style0 from "./KEFooter.vue?vue&type=style&index=0&id=f609f59a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f609f59a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesAskAdvice: require('/var/www/packages/theme/components/molecules/AskAdvice.vue').default,MoleculesSignUp: require('/var/www/packages/theme/components/molecules/SignUp.vue').default,MoleculesFooterQuickLinks: require('/var/www/packages/theme/components/molecules/FooterQuickLinks.vue').default,AtomsBusinessLogos: require('/var/www/packages/theme/components/atoms/BusinessLogos.vue').default,MoleculesFootnote: require('/var/www/packages/theme/components/molecules/Footnote.vue').default})
