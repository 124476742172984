








import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfBadge } from '@storefront-ui/vue';

type CounterVariant = 'primary' | 'secondary';

export default defineComponent({
  name: 'Counter',
  components: {
    SfBadge
  },
  props: {
    number: {
      type: Number,
      required: true
    },
    variant: {
      type: String as PropType<CounterVariant>,
      default: 'primary'
    }
  }
});
