var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quicklinks-wrapper"},_vm._l((_vm.quickLinks),function(linkgroup,index){return _c('div',{key:linkgroup.id,staticClass:"link-section"},[(!_vm.isDesktop)?[_c('AtomsAccordion',{attrs:{"isOutsideControllable":"","controllableIsOpen":linkgroup.id === _vm.openAccordionId},on:{"toggle":function($event){return _vm.toggleAccordion(linkgroup.id)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h3',{staticClass:"quicklinks-title"},[_vm._v("\n            "+_vm._s(linkgroup.urls[0].title)+"\n          ")])]},proxy:true},{key:"content",fn:function(){return _vm._l((linkgroup.urls),function(link,index){return _c('div',{key:link.id},[(index !== 0)?_c('div',{staticClass:"links-in-dropdown"},[_c('AtomsTextLink',{staticClass:"quicklink",attrs:{"link":link.url,"variant":"secondary","target":link.target}},[_vm._v("\n                "+_vm._s(link.title)+"\n              ")])],1):_vm._e()])})},proxy:true}],null,true)})]:_vm._l((linkgroup.urls),function(link,index){return _c('div',{key:link.id,staticClass:"links"},[(index == 0)?_c('h3',{staticClass:"quicklinks-title"},[_vm._v(_vm._s(link.title))]):_c('AtomsTextLink',{staticClass:"quicklink",class:{
            /* KAME-482, the last link in the fourth column is not a read more type.
                 for now this is hardcoded since there's no way of knowing
                 if a link is read more or not from backend */
            'last-link': index == linkgroup.urls.length - 1 && index !== 3
          },attrs:{"link":link.url,"variant":"secondary","target":link.target}},[_vm._v("\n          "+_vm._s(link.title)+"\n        ")])],1)}),_vm._v(" "),(index == _vm.quickLinks.length - 1)?_c('MoleculesFooterRating'):_vm._e(),_vm._v(" "),_c('MoleculesFooterSocialMedia',{directives:[{name:"show",rawName:"v-show",value:(
        (_vm.isDesktop && index == 0) ||
        (!_vm.isDesktop && index == _vm.quickLinks.length - 1)
      ),expression:"\n        (isDesktop && index == 0) ||\n        (!isDesktop && index == quickLinks.length - 1)\n      "}]})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }