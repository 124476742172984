

























import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useFooter } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'FooterRating',
  setup() {
    const { footerResult } = useFooter();

    const reviews = computed(() =>
      footerResult.value.footer_rating.filter(
        (review) =>
          (review as { code_english: string }).code_english !== 'write_review'
      )
    );
    const handleRedirect = (url: string) => {
      window.open(url);
    };

    return {
      footerResult,
      reviews,
      subscribeTitle: footerResult.value.footer_review.title,
      subscribeUrl: footerResult.value.footer_review.url as string,
      handleRedirect
    };
  }
});
