import { render, staticRenderFns } from "./FooterFootnoteLinks.vue?vue&type=template&id=39a36f8c&scoped=true&"
import script from "./FooterFootnoteLinks.vue?vue&type=script&lang=ts&"
export * from "./FooterFootnoteLinks.vue?vue&type=script&lang=ts&"
import style0 from "./FooterFootnoteLinks.vue?vue&type=style&index=0&id=39a36f8c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39a36f8c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsTextLink: require('/var/www/packages/theme/components/atoms/TextLink.vue').default})
