import { render, staticRenderFns } from "./MegaMenuMobile.vue?vue&type=template&id=7b47f0cf&scoped=true&"
import script from "./MegaMenuMobile.vue?vue&type=script&lang=js&"
export * from "./MegaMenuMobile.vue?vue&type=script&lang=js&"
import style0 from "./MegaMenuMobile.vue?vue&type=style&index=0&id=7b47f0cf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b47f0cf",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCardMegaMenuLevelOneMobile: require('/var/www/packages/theme/components/atoms/CardMegaMenuLevelOneMobile.vue').default,AtomsMegaMenuLevelOneMobile: require('/var/www/packages/theme/components/atoms/MegaMenuLevelOneMobile.vue').default,AtomsMegaMenuLevelTwoMobile: require('/var/www/packages/theme/components/atoms/MegaMenuLevelTwoMobile.vue').default})
