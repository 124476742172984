












import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'Tab',
  components: {
    SfButton
  },
  props: {
    iconRight: {
      type: Boolean,
      default: false
    },
    iconDown: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
});
