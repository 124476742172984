import { ref } from '@nuxtjs/composition-api';
const shouldHideHeader = ref(false);

export default function useDefaultHeaderToggle() {
  const toggleHideHeader = (newValue) => {
    shouldHideHeader.value = newValue;
  };

  return {
    shouldHideHeader,
    toggleHideHeader
  };
}
