var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['megamenu-sublevel2-container', _vm.activeTab]},[(
      _vm.levelOne[_vm.activeTab]['groups'] &&
      _vm.levelOne[_vm.activeTab]['groups'].length > 0
    )?_c('div',{staticClass:"megamenu-sublevel2 list"},_vm._l((_vm.levelOne[_vm.activeTab]['groups']),function(levelTwo,indexTwo){return _c('ul',{key:indexTwo},[(levelTwo.showTitle)?_c('li',[_c('nuxt-link',{class:{ 'direct-link': levelTwo.isDirectLink },attrs:{"to":_vm.formatUrl(levelTwo.url)}},[_c('strong',[_vm._v(_vm._s(levelTwo.subDesc))])])],1):_vm._e(),_vm._v(" "),(_vm.activeTab !== 'inspiratie')?_vm._l((levelTwo['tags']),function(levelThree,indexThree){return _c('li',{key:indexThree},[_c('nuxt-link',{class:{ 'direct-link': levelThree.isDirectLink },attrs:{"to":_vm.formatUrl(levelThree.link)}},[_vm._v("\n            "+_vm._s(levelThree.desc)+"\n          ")])],1)}):_vm._e(),_vm._v(" "),(levelTwo.discoverMore)?_c('li',[_c('nuxt-link',{staticClass:"more-link",class:{
            'direct-link': levelTwo.isDirectLink,
            'inspiration-more': _vm.activeTab === 'inspiratie'
          },attrs:{"to":_vm.getDiscoverMoreUrl(levelTwo)}},[_vm._v("\n          "+_vm._s(levelTwo.discoverMore.name)+"\n        ")])],1):_vm._e()],2)}),0):_vm._e(),_vm._v(" "),(
      _vm.levelOne[_vm.activeTab]['products'] &&
      _vm.levelOne[_vm.activeTab]['products'].length > 0
    )?_c('div',{staticClass:"megamenu-sublevel2 product"},[_vm._l((_vm.levelOne[_vm.activeTab]['products']),function(productGroup,index){return _c('ul',{key:(index + "productgroup")},[_c('li',[_c('strong',[_vm._v(_vm._s(productGroup.desc))])]),_vm._v(" "),_vm._l((productGroup['tags']),function(product,indexProd){return [(product.desc)?_c('li',{key:(indexProd + "product")},[_c('AtomsThumbnail',{attrs:{"product":Object.assign({}, product,
              {url: _vm.formatUrl(product.url),
              title: product.desc,
              description: null,
              image: _vm.mediaUrl(product.media)}),"variant":_vm.activeTab === 'inspiratie' ? 'inspiration' : undefined}})],1):_vm._e()]})],2)}),_vm._v(" "),(_vm.levelOne[_vm.activeTab].discoverMore)?_c('ul',[_c('li',[_c('nuxt-link',{attrs:{"to":_vm.getDiscoverMoreUrl(_vm.levelOne[_vm.activeTab])}},[_vm._v("\n          "+_vm._s(_vm.levelOne[_vm.activeTab].discoverMore.name)+"\n        ")])],1)]):_vm._e()],2):_vm._e(),_vm._v(" "),(
      (_vm.levelOne[_vm.activeTab]['content'] &&
        _vm.levelOne[_vm.activeTab]['content'].length > 0) ||
      (_vm.levelOne[_vm.activeTab].mediaContent &&
        _vm.levelOne[_vm.activeTab].mediaContent.length > 0)
    )?_c('div',{staticClass:"megamenu-sublevel2 extra"},[(
        _vm.levelOne[_vm.activeTab]['content'] &&
        _vm.levelOne[_vm.activeTab]['content'].length > 0
      )?_vm._l((_vm.levelOne[_vm.activeTab]['content']),function(levelTwo,indexTwo){return _c('ul',{key:indexTwo},[_c('li',[_c('strong',[_vm._v(_vm._s(levelTwo.desc))])]),_vm._v(" "),_vm._l((levelTwo['content']),function(levelThree,indexThree){return _c('li',{key:indexThree},[_c('nuxt-link',{attrs:{"to":_vm.formatUrl(levelThree.link)}},[_vm._v("\n            "+_vm._s(levelThree.description)+"\n          ")])],1)})],2)}):_vm._e(),_vm._v(" "),(
        _vm.levelOne[_vm.activeTab].mediaContent &&
        _vm.levelOne[_vm.activeTab].mediaContent.length > 0
      )?[_vm._l((_vm.levelOne[_vm.activeTab].mediaContent),function(media,i){return [(media.desc)?_c('AtomsPromo',{key:i + _vm.levelOne[_vm.activeTab].mainRef,attrs:{"title":media.desc,"description":media.desc,"image":_vm.mediaUrl(media.media),"link":_vm.formatUrl(media.link)}}):_vm._e()]})]:_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }