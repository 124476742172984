












import { defineComponent } from '@nuxtjs/composition-api';
import { useFooter } from '@vue-storefront/novulo';

export default defineComponent({
  name: 'SignUp',
  setup() {
    const { footerResult } = useFooter();

    const handleRedirect = () => {
      window.location.href = footerResult.value.footer_subscribe.url;
    };

    return {
      footerResult,
      handleRedirect,
      buttonText: footerResult.value.footer_subscribe.title
    };
  }
});
