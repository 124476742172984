






import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    suggestion: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const suggestionDisplayValues = computed(() => {
      let rest = props.suggestion;
      let startPart = '';
      if (
        props.suggestion
          .toLowerCase()
          .startsWith(props.searchQuery.toLowerCase())
      ) {
        const regEx = new RegExp(props.searchQuery, 'ig');
        rest = props.suggestion.replace(regEx, '');
        startPart = props.searchQuery;
      }
      return {
        startPart,
        rest
      };
    });

    return {
      suggestionDisplayValues
    };
  }
});
